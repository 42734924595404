.resume {
  background-image: url(../images/background.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
  overflow-y: auto;
}

.top-banner {
  background-color: #4C614A;
  height: 5em;
}

.intro-section {
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.section {
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-sm {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1em;
  margin-top: 15%;
}

.card-md {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1em;
  margin: 10%;
}

.resume-show {
  animation-duration: 3s;
  animation-name: popin;
}

@keyframes popin {
  0% { opacity: 0; transform: scale(0.5); }
  100% { opacity: 1; transform: scale(1); }
}

.wide {
  width: 50%;
}

.title-1 {
  font-family: 'Raleway', serif;
  font-size: 3rem;
}

.title-2 {
  font-family: 'Raleway', serif;
  font-size: 2rem;
}

.title-3 {
  font-family: 'Raleway', serif;
  font-size: 1.5rem;
  margin: 1em;
}

.title-4 {
  font-family: 'Raleway', serif;
  font-size: 1rem;
}

.dark-background {
  background-color: #4C614A;
  color: #e2dbd0;
}

.medium-background {
  background-color: #cbbfac;
  color: #4C614A;
}

.contact {
  margin-top: 1em;
}

.contact > a {
  padding: 0.75em;
}

.more-arrows {
  margin-top: auto;
  padding-bottom: 2em;
}

.more-arrow-1 {
  animation: blink-1 4s ease-in infinite;
}

@keyframes blink-1 {
  from, to { opacity: 0 }
  33% { opacity: 1 }
  66% { opacity: 0.5 }
}

.more-arrow-2 {
  animation: blink-2 4s ease-in infinite;
}

@keyframes blink-2 {
  from, to { opacity: 0.5 }
  33% { opacity: 0 }
  66% { opacity: 1 }
}

.more-arrow-3 {
  animation: blink-3 4s ease-in infinite;
}

@keyframes blink-3 {
  from, to { opacity: 1 }
  33% { opacity: 0.5 }
  66% { opacity: 0 }
}

@media screen and (max-width: 1024px) {
	.wide {
    width: 90%;
  }
}

.competencies {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 2em;
  flex-flow: wrap;
}

.group {
  padding: 0 2em;
}

.operating-systems {
  display: flex;
}

.operating-systems > img {
  width: 6em;
  padding: .5em 1em;
}

.education {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: .5em;
}

.education > img {
  width: 4em;
}

.education-details {
  flex-direction: column;
  margin: 0 1em;
}

.education-details > .title-3 {
  margin: 0;
  text-align: left;
}

.education-details > .extra-details {
  font-family: 'Raleway', serif;
  font-size: 1rem;
}

.experience > .title-3 {
  margin: 1em 1em 0;
}