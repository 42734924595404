body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: 100%;
}

.flex-column {
	display: flex;
	flex-flow: column;
}

.flex {
	display: flex;
}

.dropdown button.btn-primary {
	color: #5c6166;
	background-color: white;
	border-color: #ced4da;
}

.dropdown button.btn-primary.dropdown-toggle:focus {
	color: #5c6166;
	background-color: white;
	border-color: white;
	box-shadow: 0 0 0 0.1rem #5c6166;
}

h1 {
	padding: 15px 0 0 15px;
	float: left;
}

.games {
	padding: 1em;
}

.header-actions {
	display: flex;
    flex-direction: row;
	justify-content: flex-end;
	float: right;
	padding-left: 1em;
}

.header-action {
	float: right;
	padding-right: 1em;
	padding-top: 18px;
}

.picks {
	margin-top: 18px;
	margin-right: 16px;
}

.copy {
	min-width: 102px;
}

.team-name {
	width: 35%;
}

.total-points {
	width: 200px !important;
	padding-left: 1em;
}

.resume {
  background-image: url(/static/media/background.7ae679a5.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
  overflow-y: auto;
}

.top-banner {
  background-color: #4C614A;
  height: 5em;
}

.intro-section {
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.section {
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-sm {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1em;
  margin-top: 15%;
}

.card-md {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1em;
  margin: 10%;
}

.resume-show {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: popin;
          animation-name: popin;
}

@-webkit-keyframes popin {
  0% { opacity: 0; -webkit-transform: scale(0.5); transform: scale(0.5); }
  100% { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }
}

@keyframes popin {
  0% { opacity: 0; -webkit-transform: scale(0.5); transform: scale(0.5); }
  100% { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }
}

.wide {
  width: 50%;
}

.title-1 {
  font-family: 'Raleway', serif;
  font-size: 3rem;
}

.title-2 {
  font-family: 'Raleway', serif;
  font-size: 2rem;
}

.title-3 {
  font-family: 'Raleway', serif;
  font-size: 1.5rem;
  margin: 1em;
}

.title-4 {
  font-family: 'Raleway', serif;
  font-size: 1rem;
}

.dark-background {
  background-color: #4C614A;
  color: #e2dbd0;
}

.medium-background {
  background-color: #cbbfac;
  color: #4C614A;
}

.contact {
  margin-top: 1em;
}

.contact > a {
  padding: 0.75em;
}

.more-arrows {
  margin-top: auto;
  padding-bottom: 2em;
}

.more-arrow-1 {
  -webkit-animation: blink-1 4s ease-in infinite;
          animation: blink-1 4s ease-in infinite;
}

@-webkit-keyframes blink-1 {
  from, to { opacity: 0 }
  33% { opacity: 1 }
  66% { opacity: 0.5 }
}

@keyframes blink-1 {
  from, to { opacity: 0 }
  33% { opacity: 1 }
  66% { opacity: 0.5 }
}

.more-arrow-2 {
  -webkit-animation: blink-2 4s ease-in infinite;
          animation: blink-2 4s ease-in infinite;
}

@-webkit-keyframes blink-2 {
  from, to { opacity: 0.5 }
  33% { opacity: 0 }
  66% { opacity: 1 }
}

@keyframes blink-2 {
  from, to { opacity: 0.5 }
  33% { opacity: 0 }
  66% { opacity: 1 }
}

.more-arrow-3 {
  -webkit-animation: blink-3 4s ease-in infinite;
          animation: blink-3 4s ease-in infinite;
}

@-webkit-keyframes blink-3 {
  from, to { opacity: 1 }
  33% { opacity: 0.5 }
  66% { opacity: 0 }
}

@keyframes blink-3 {
  from, to { opacity: 1 }
  33% { opacity: 0.5 }
  66% { opacity: 0 }
}

@media screen and (max-width: 1024px) {
	.wide {
    width: 90%;
  }
}

.competencies {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 2em;
  flex-flow: wrap;
}

.group {
  padding: 0 2em;
}

.operating-systems {
  display: flex;
}

.operating-systems > img {
  width: 6em;
  padding: .5em 1em;
}

.education {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: .5em;
}

.education > img {
  width: 4em;
}

.education-details {
  flex-direction: column;
  margin: 0 1em;
}

.education-details > .title-3 {
  margin: 0;
  text-align: left;
}

.education-details > .extra-details {
  font-family: 'Raleway', serif;
  font-size: 1rem;
}

.experience > .title-3 {
  margin: 1em 1em 0;
}
.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: .5em;
	width: 15em;
}

.image {
	width: 4em;
}

.label {
	font-family: 'Raleway', serif;
	font-size: 1.5em;
	margin: 0 .5em;
    text-align: left;
}
