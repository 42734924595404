.flex-column {
	display: flex;
	flex-flow: column;
}

.flex {
	display: flex;
}

.dropdown button.btn-primary {
	color: #5c6166;
	background-color: white;
	border-color: #ced4da;
}

.dropdown button.btn-primary.dropdown-toggle:focus {
	color: #5c6166;
	background-color: white;
	border-color: white;
	box-shadow: 0 0 0 0.1rem #5c6166;
}

h1 {
	padding: 15px 0 0 15px;
	float: left;
}

.games {
	padding: 1em;
}

.header-actions {
	display: flex;
    flex-direction: row;
	justify-content: flex-end;
	float: right;
	padding-left: 1em;
}

.header-action {
	float: right;
	padding-right: 1em;
	padding-top: 18px;
}

.picks {
	margin-top: 18px;
	margin-right: 16px;
}

.copy {
	min-width: 102px;
}

.team-name {
	width: 35%;
}

.total-points {
	width: 200px !important;
	padding-left: 1em;
}
