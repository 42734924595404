.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: .5em;
	width: 15em;
}

.image {
	width: 4em;
}

.label {
	font-family: 'Raleway', serif;
	font-size: 1.5em;
	margin: 0 .5em;
    text-align: left;
}